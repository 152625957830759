<template>
  <article id="wrap">
    <AppHeader theme="gray">
      <div style="color: #262626;">マスターを選択</div>
    </AppHeader>
    <section class="contents">
      <div class="contentInner pb_80">
        <TalkRoomList :talk_rooms="talk_rooms" mode="selector"></TalkRoomList>
      </div><!--.contentInner-->
    </section><!--.contents-->

    <AppFooter theme="yellow" fixed></AppFooter>

  </article>
</template>

<script>
import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';
import TalkRoomList from '@/components/TalkRoomList';
export default {
  name: 'ArtistAdd',
  components: { AppHeader, AppFooter, TalkRoomList },
  created () {
    this.initialize();
  },
  data() {
    return {
      talk_rooms: []
    }
  },
  methods: {
    async initialize () {
      const response = await this.$http.get('talk_room');
      this.talk_rooms = response.data.data;
      if (this.$store.state.loginUser.artist_id) {
        this.talk_rooms =  this.talk_rooms.filter(talk_room => talk_room.artist_id !== this.$store.state.loginUser.artist_id);
      }
    }
  }
}
</script>
